<template>
  <div>
    <ManageUser />
  </div>
</template>
<script>
import ManageUser from "@/components/ManageUser/ManageUser";
export default {
  components: {
    ManageUser,
  },
  created() {},
};
</script>